/*------------- #VIDEO-PLAYER --------------*/



.video-player {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  margin: 20px 0;

  img {
	width: 100%;
  }

  .video-content {
	position: absolute;
	bottom: 25px;
	left: 25px;
	color: $white-color;
	z-index: 5;
	opacity: 0;
	transition: all .3s ease;
	padding: 0;

	.title {
	  color: $white-color;
	  display: block;
	}
  }

  .play-video {
	opacity: 0;
  }

  &:hover {
	.video-content {
	  opacity: 1;
	}

	.play-video {
	  opacity: 1;
	}

	.overlay {
	  opacity: 1;
	}

	&:after {
	  opacity: 1;
	}
  }

  &:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: inset 0px -120px 55px -30px rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: all .3s ease;
  }
}


/*------------- Responsive mode styles --------------*/

@media (max-width: 480px) {
  .video-player .video-content .title {
	display: none;
  }
}