// colour palette

$dark: #3f3e41;
$darkerGrey: #868289;
$grey: #c5c4c4;
$offWhite: #efefef;
$light: #ffffff;
$blue: #4c95e2;
$danger: #c61b64;
$altDanger: #a147ad;
$warning: #fb5726;
$highlight: #e3f73b;
$success: #47ad6d;
$purple: #80448c;
$darkpurple: #6c236b;
