/*------------- #W-ABOUT --------------*/



.w-about {
  .logo {
	color: inherit;
	overflow: hidden;
	position: relative;
	margin-bottom: 30px;
	padding: 0;
  }

  ul {
	margin-bottom: 0;
  }

  img {
	margin-right: 17px;
  }

  .logo-title {
	color: $heading-color;
  }
}