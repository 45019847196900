/*------------- #BOOTSTRAP-CUSTOMIZATION --------------*/


ul {
  list-style: none;
  padding: 0;
}

a {
  transition: all .15s ease;

  &:hover {
    color: $primary-color;
  }
}

select,
input,
.form-control {
  background-color: transparent;
}

select, input {
  display: block;
  width: 100%;
  padding: 1.1rem 1.1rem;
  font-size: .875rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: transparent;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #e6ecf5;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

p {
  font-size: .875rem;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #515365;
}

.nav-tabs .nav-item {
  position: relative;
}

.form-group {
  position: relative;
  margin-bottom: 1.4rem;
}

.form-group textarea {
  resize: none;
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label {
  top: 18px;
  font-size: 14px;
  line-height: 1.42857;
  left: 20px;
}

.form-group.label-static label.control-label,
.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label,
.form-group.has-bootstrap-select label.control-label {
  top: 10px;
  font-size: 11px;
  line-height: 1.07143;
}

.form-group.label-static label.control-label,
.form-group.label-placeholder label.control-label,
.form-group.label-floating label.control-label {
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
}

.card {
  border: none;
}

.card-header {
  a {
    color: #515365;
    display: block;

    &:hover, &:focus {
      color: #515365;
    }
  }
  i, svg {
    float: right;
    transition: all .3s ease;
    margin-top: 4px;
  }

  .collapsed i, .collapsed svg {
    transform: rotate(-90deg);
  }

  .collapsed .event-status-icon i, .collapsed .event-status-icon svg {
    transform: rotate(0);
  }
}

blockquote {
  margin: 3em 0;
  padding: 15px 80px 15px 80px;
  position: relative;

  h6 {
    font-size: 11px;
    font-weight: 400;
    color: $body-font-color;

    span {
      font-weight: 700;
      display: block;
      color: $heading-color;
    }
  }

  p {
    margin: 0 0 15px !important;
    font-size: 22px;
    line-height: 1.3em;
    font-weight: 300;
    position: relative;
    color: $heading-color;

    &:before {
      content: "";
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -80px;
      display: block;
      background-color: $primary-color;
    }

    @media (max-width: 640px) {
      font-size: 16px;
    }
  }
}

a > svg {
  transition: all .15s ease;
}

svg {
  transition: all .3s ease;
}

button {

  &.btn {
    padding: .5rem 1rem;
  }

  &.btn-md {
    padding: 1rem 3.5rem;
    font-size: 0.75rem;
    border-radius: 0.3rem;
  }

  &.btn-md-2 {
    padding: .8rem 2.1rem;
    font-size: 0.688rem;
    border-radius: 0.3rem;
  }

  &.btn-lg {
    padding: 1rem 3rem;
  }
}

.form-inline {

  .btn {
    margin-bottom: 0;
  }

  & > * + * {
    margin-left: 16px;
  }
}

.h1, .h2, .h3, .h4, h1, h2, h3, h4 {
  font-weight: $headings-font-weight-h1-h4;
}


img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.modal-dialog {
  pointer-events: auto;
}

.was-validated .form-control:valid ~ .invalid-feedback, .was-validated .form-control:valid ~ .invalid-tooltip {
  display: none;
}

.was-validated .form-control:valid ~ .material-input{
 &:after {
   content: '\f00c';
   font-family: 'Font Awesome 5 Free';
   font-weight: 900;
   position: absolute;
   display: block;
   width: 20px;
   height: 20px;
   line-height: 20px;
   border-radius: 100%;
   font-size: 9px;
   background-color: #08ddc1;
   color: #fff;
   text-align: center;
   right: 20px;
   top: 50%;
   margin-top: -10px;
 }
}


/*================= Styles for custom input type="number" ============*/


.number-spinner.input-group {
  .form-group {
    margin-bottom: 0;
  }

  .form-control {
    width: 100%;
    padding: .5rem 1.1rem;
    max-width: 80px;
  }

  .input-group-btn > .btn {
    margin-bottom: 0;
    height: 100%;
    background-color: $primary-color;
    z-index: 5;
  }

  .glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $white-color;

    &:before {
      content: "\2212";
    }
  }

  .glyphicon-plus::before {
    content: "\2b";
  }

  &.number-spinner--secondary {
    .input-group-btn > .btn {
      background-color: $secondary-color;
    }
  }

  &.number-spinner--breez {
    .input-group-btn > .btn {
      background-color: $breez;
    }
  }

  &.number-spinner--green {
    .input-group-btn > .btn {
      background-color: $green;
    }
  }
}

.btn-secondary:not([disabled]):not(.disabled):active {
  background-color: #fff;
  border-color: $border-color;
  color: $heading-color;
  box-shadow: none;
}


/*================= Responsive Mode ============*/

@media (max-width: 1024px) {
  .form-group.label-floating label.control-label, .form-group.label-placeholder label.control-label {
    left: 15px;
  }
}

@media (max-width: 600px) {
  .card-header a {
    font-size: 18px;
  }

  .modal-dialog {
    margin: 30px auto;
  }
}

@media (max-width: 360px) {
  .card-header a {
    font-size: 14px;
  }

  .form-group.label-floating label.control-label, .form-group.label-placeholder label.control-label {
    font-size: 12px;
  }
}
