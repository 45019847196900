@import "colours";

// main header
.ui.menu.mainHeader {
    margin: 0 !important;
    border-radius: 0 !important;
    height: 75px;

    .item.brand {
        font-size: 12pt;
        color: $light;
    }

    .item.menuItem {
        font-size: 10pt;
        color: $dark;
        &:hover {
            cursor: pointer;
            background-color: $offWhite !important;
            color: $dark;
        }
    }
}

.ui.menu .dropdown.item .menu {
    left: -50px !important;
}

// secondary header
.ui.menu.secondaryHeader {
    margin: 0 !important;
    border-radius: 0 !important;
    height: 60px;
    box-shadow: none;
    font-size: 10pt;

    .item.subMenuItem {
        color: lighten($dark, 35%);
    }
}

// notation header icon button

.mtudeIconButton {
    white-space: nowrap;
    cursor: pointer;
    color: $grey;
    font-size: 16px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mtudeIconButton:hover {
    color: $blue !important;
}

.iconFont {
    font-family: 'Ubuntu', sans-serif;
    font-size: 12.5pt;
}

.open-search {
    background-color: #494c62 !important;
}

.ui.bottom.popup:before {display: none}

.demoplayer .ui.dimmer {
    z-index: 20;
}

.olympus-styles .search-bar .selectize-dropdown {
    border: 0px !important;
}
