/*------------- #LANDING --------------*/


.landing-content {
  color: $white-color;
  margin-bottom: 30px;

  & > *:first-child {
	font-weight: 300;
  }

  & > *:last-child {
	margin-bottom: 0;
  }

  & > * {
	color: inherit;
	margin-bottom: 45px;
  }
}

.main-header.main-header-fullwidth {
  width: 100%;
  max-width: 100%;
}

.main-header.main-landing {
  width: 100%;
  max-width: 100%;

  .logo {
	text-align: center;
	display: block;
	background-color: transparent;
	margin-bottom: 60px;

	img {
	  display: inline-block;
	  margin: 0 auto 10px;
	}
  }

  h1 {
	font-size: 36px;
	font-weight: 300;
	margin-bottom: 40px;
  }

  p {
	margin-bottom: 60px;
  }
}

.img-rocket {
  position: absolute;
  bottom: 160px;
  right: 30%;
}

.landing-item {
  text-align: center;
  margin-bottom: 50px;

  .title {
	font-weight: 700;
  }

  .full-block {
	z-index: 999;
  }

  .landing-item-thumb {
	position: relative;
	margin-bottom: 25px;
	box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.3);

	img {
	  width: 100%;
	}

	.btn {
	  margin-bottom: 0;
	  position: absolute;
	  opacity: 0;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	  max-width: 90%;
	}

	.new {
	  display: block;
	  position: absolute;
	  right: 5px;
	  top: 5px;
	  width: 50px;
	  height: 50px;
	  border-radius: 100%;
	  background-color: $primary-color;
	  color: $white-color;
	  line-height: 50px;
	  text-align: center;
	  text-transform: uppercase;
	  font-size: 14px;
	  font-weight: 700;
	}
  }

  &:hover {
	.overlay {
	  opacity: 1;
	}

	.btn {
	  opacity: 1;
	}
  }
}

.landing-main-content {
  text-align: center;
  margin: 80px 0;

  svg {
	fill: $primary-color;
	margin-bottom: 30px;
  }

  .btn {
	margin: 40px 0;
  }

  .title {
	font-weight: 300;
	margin-bottom: 30px;
  }
}



/*------------- Responsive mode styles --------------*/

@media (max-width: 800px) {
  .img-rocket {
	right: 5px;
  }

  .landing-content > * {
	margin-bottom: 20px;
  }
}

@media (max-width: 420px) {
  .img-rocket {
	bottom: 60px;
  }
}
