/*------------- #PHOTO-GALLERY --------------*/


.photo-gallery {
  display: flex;
  top: 0;
  right: 65px;
  height: 100%;
  position: absolute;
  border-bottom: 0;

  .nav-link {
	border-radius: 0;
	padding: 30px 26px;
	fill: #c2c5d9;
	background-color: #fafbfd;
	border-color: transparent;
	height: 100%;
	border-right-color: $border-color;
	border-left-color: $border-color;
	border-top-color: transparent;

	&.active {
	  fill: $primary-color;
	  background-color: $white-color;
	  border-top-color: transparent;
	}

	&:hover {
	  border-right-color: $border-color;
	  border-left-color: $border-color;
	  border-top-color: transparent;
	  border-bottom-color: transparent;
	}

	svg {
	  width: 18px;
	  height: 20px;
	}
  }

  &.nav-tabs .nav-item {
	margin-bottom: 0;
	max-height: 100%;

	& + .nav-item {
	  margin-left: -1px;
	}
  }
}

.block-btn {
  padding-right: 170px;
}

.photo-item {
  display: inline-block;
  padding: 0 12px 12px 0;
  margin-right: -3px;
  position: relative;
  overflow: hidden;

  .overlay {
	bottom: 12px;
	right: 12px;
	border-radius: 5px;
  }

  img {
	border-radius: 5px;
	width: 100%;
  }

  &:last-child {
	margin-right: 0;
  }

  .more {
	position: absolute;
	top: 25px;
	right: 25px;
	color: $white-color;
	fill: $white-color;
	opacity: 0;
	transition: all .3s ease;
  }

  .content {
	position: absolute;
	bottom: 25px;
	left: 20px;
	opacity: 0;
	transition: all .3s ease;

	.title {
	  color: $white-color;
	  display: block;
	  margin-bottom: 0;
	}

	time {
	  font-size: 12px;
	  color: #b6bbd7;
	}
  }

  .post-add-icon {
	fill: $white-color;
	position: absolute;
	right: 25px;
	bottom: 30px;
	opacity: 0;
	transition: all .3s ease;
  }

  &:hover {
	.more, .content, .post-add-icon, .overlay {
	  opacity: 1;
	}
  }

}

.photo-album-wrapper {
  margin-right: -12px;
}

.photo-album-item-wrap {
  display: inline-block;
  margin-right: -3px;
  padding: 0 12px 12px 0;
  vertical-align: top;
}

.photo-album-item {
  position: relative;
  border-radius: 5px;
  border: 1px solid $border-color;
  background-color: $white-color;

  &:last-child {
	margin-right: 0;
  }

  .photo-item {
	float: none;
	margin: 0;
	border-radius: 3px;
	display: block;
	padding: 0;

	img {
	  width: 100%;
	  border-radius: 0;
	}

	.overlay {
	  bottom: 0;
	  right: 0;
	  border-radius: 0;
	}
  }

  .content {
	padding: 25px 25px 20px;
	text-align: center;
  }

  .title {
	display: block;
	margin-bottom: 0;
  }

  .sub-title {
	margin-bottom: 30px;
	display: block;
  }

  .friends-harmonic {
	display: inline-block;
  }

  &.create-album {
	background-color: transparent;
	border: 2px dashed #bbc0d5;

	.content {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	  width: 90%;
	}

	.btn-control {
	  margin-bottom: 20px;
	  float: none;
	}

	svg {
	  width: 20px;
	  height: 20px;
	}
  }

  .form-group {
	margin-bottom: 0;

	& > img {
	  width: 100%;
	  border-radius: 5px 5px 0 0;
	  overflow: hidden;
	}
  }
}


/*------------- Responsive mode styles --------------*/

@media (max-width: 768px) {
  .photo-gallery.nav-tabs .nav-item {
	width: auto;
  }
}

@media (max-width: 480px) {
  .photo-item .post-add-icon {
	right: auto;
	bottom: auto;
	left: 25px;
	top: 25px;
  }

  .block-btn {
	.btn + .btn {
	  margin-left: 0;
	  margin-top: 10px;
	}
  }

  .photo-album-item.create-album .content {
	position: relative;
	transform: none;
	top: auto;
	left: auto;
	width: 100%;
  }
}