/*------------- #HELPERS --------------*/



.ovh {
  overflow: hidden;
}

.f-left {
  float: left;
}
.f-right {
  float: right;
}

.f-none {
  float: none;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;

  .btn {
     margin-left: 20px;
   }

  .w-search {
    display: inline-block;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(43, 45, 59, 0.2);
  transition: all .3s ease;
  opacity: 0;

  &.overlay-dark {
    background: rgba(43, 45, 59, 0.8);
  }
}


// Positioning

.table {
  display: table;
}

.inline-items {
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
  float: none;

  @media (max-width: 640px) {
    display: block;
    float: left;
  }
}

.text-t-none {
  text-transform: none;
}

.centered-on-mobile {
  @media (max-width: 480px) {
    text-align: center;
  }
}

.no-padding {
  padding: 0;
}

.padding-r-0 {
 padding-right: 0;
}

.padding-l-0 {
  padding-left: 0;
}

.mt50 {
  margin-top: 50px;
}

.no-margin {
  margin: 0;
}

.negative-margin-top150 {
  margin-top: -150px;

  @media (max-width: 1024px) {
    margin-top: 0;
  }
}

.negative-margin-top50 {
  margin-top: -50px;
}

.negative-margin-right150 {
  margin-right: -150px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.mobile-margin-25 {
  margin: 0 25px;
}

.presentation-margin {
  margin: 40px 0 20px;
}

.full-block {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.display-flex {
  display: flex;
  align-items: center;

  &.content-center {
    justify-content: center;
  }

  &.content-around {
    justify-content: space-around;
  }
}

.inline-block {
  display: inline-block;
}

.padding40 {
  padding: 40px;
}

.padding80 {
  padding: 80px;
}

.bg-violet {
  background-color: $violet;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.bg-purple {
  background-color: $purple;
}

.bg-blue {
  background-color: $blue;
}

.bg-blue-light {
  background-color: $blue-light;
}

.bg-breez {
  background-color: $breez;
}

.bg-breez-light {
  background-color: $breez-light;
}

.bg-grey {
  background-color: $grey;
}

.bg-grey-light {
  background-color: $grey-light;
}

.bg-grey-lighter {
  background-color: $grey-lighter;
}

.bg-orange {
  background-color: $orange;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-smoke {
  background-color: $smoke;
}

.bg-smoke-light {
  background-color: $smoke-light;
}

.bg-green {
  background-color: $green;
}

.bg-facebook {
  background-color: $c-facebook;
}

.bg-twitter {
  background-color: $c-twitter;
}

.bg-dribbble {
  background-color: $c-dribbble;
}

.bg-google {
  background-color: $c-google;
}


.c-primary {
  color: $primary-color;
}

.c-white {
  color: $white-color;
}

.c-grey {
  color: $body-font-color;
}

.c-orange {
  color: $orange;
}

.c-secondary {
  color: $secondary-color;
}

.c-yellow {
  color: $yellow;
}

.c-purple {
  color: $purple;
}

.c-green {
  color: $green;
  fill: $green;
}

.c-facebook {
  color: $c-facebook;
}

.c-twitter {
  color: $c-twitter;
}

.c-dribbble {
  color: $c-dribbble;
}

.c-rss {
  color: $c-rss;
}

.c-spotify {
  color: $c-spotify;
}

.bold {
  font-weight: 800;
}

.weight-normal {
  font-weight: 400;
}

.weight-light {
  font-weight: 300;
}

.custom-color * {
  color: inherit;
}

.flip-button {
  box-sizing: content-box;
  cursor: pointer;
  outline: none;
  padding: 0;
  position: relative;
  -webkit-transform: scale(1) rotate(360deg);
  transform: scale(1) rotate(360deg);
  -webkit-transition: -webkit-transform 150ms cubic-bezier(.4, 0, 1, 1);
  transition: transform 150ms cubic-bezier(.4, 0, 1, 1);
}
.flip-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
}
.flip-icon > * {
  left: 0;
  position: absolute;
  top: -3px;
  -webkit-transition: all .2s cubic-bezier(.4, 0, .2, 1);
  transition: all .2s cubic-bezier(.4, 0, .2, 1);
}
.flip-icon svg:last-of-type, .flip-button.active .flip-icon svg:first-of-type {
  opacity: 0;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.flip-button.active .flip-icon svg:last-of-type {
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.medium-padding80 {
  padding: 80px 0;

  @media (max-width: 1024px) {
    padding: 45px 0;
  }

  @media (max-width: 800px) {
    padding: 35px 0;
  }
}

.medium-padding100 {
  padding: 100px 0;

  @media (max-width: 1024px) {
    padding: 50px 0;
  }

  @media (max-width: 800px) {
    padding: 35px 0;
  }
}

.medium-padding120 {
  padding: 120px 0;

  @media (max-width: 1024px) {
    padding: 50px 0;
  }

  @media (max-width: 800px) {
    padding: 35px 0;
  }
}

.medium-padding180 {
  padding: 180px 0;

  @media (max-width: 1024px) {
    padding: 60px 0;
  }

  @media (max-width: 800px) {
    padding: 40px 0;
  }
}

.pb100 {
  padding-bottom: 100px;

  @media (max-width: 1024px) {
    padding-bottom: 50px;
  }

  @media (max-width: 800px) {
    padding-bottom: 35px;
  }
}

.pt80 {
  padding-top: 80px;

  @media (max-width: 1024px) {
    padding-top: 45px;
  }

  @media (max-width: 800px) {
    padding-top: 35px;
  }
}

.pt100 {
  padding-top: 100px;

  @media (max-width: 1024px) {
    padding-top: 50px;
  }

  @media (max-width: 800px) {
    padding-top: 35px;
  }
}

.pb120 {
  padding-bottom: 120px;

  @media (max-width: 1024px) {
    padding-bottom: 50px;
  }

  @media (max-width: 800px) {
    padding-bottom: 35px;
  }
}

.pt120 {
  padding-top: 120px;

  @media (max-width: 1024px) {
    padding-top: 50px;
  }

  @media (max-width: 800px) {
    padding-top: 35px;
  }
}

.pb160 {
  padding-bottom: 160px;

  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }

  @media (max-width: 800px) {
    padding-bottom: 35px;
  }
}

.pt160 {
  padding-top: 160px;

  @media (max-width: 1024px) {
    padding-top: 60px;
  }

  @media (max-width: 800px) {
    padding-top: 35px;
  }
}

.pb80 {
  padding-bottom: 80px;

  @media (max-width: 1024px) {
    padding-bottom: 45px;
  }

  @media (max-width: 800px) {
    padding-bottom: 35px;
  }
}

.pb60 {
  padding-bottom: 60px;

  @media (max-width: 1024px) {
    padding-bottom: 40px;
  }

  @media (max-width: 800px) {
    padding-bottom: 20px;
  }
}

.pt60 {
  padding-top: 60px;

  @media (max-width: 1024px) {
    padding-top: 40px;
  }

  @media (max-width: 800px) {
    padding-top: 20px;
  }
}

.pb30 {
  padding-bottom: 30px;
}

.pt30 {
  padding-top: 30px;
}

.mt60 {
  margin-top: 60px;

  @media (max-width: 800px) {
    margin-top: 35px;
  }
}

.mb30 {
  margin-bottom: 30px;
}

.mb60 {
  margin-bottom: 60px;

  @media (max-width: 800px) {
    margin-bottom: 35px;
  }
}

.half-height-bg {
  position: absolute;
  height: 50%;
  left: 0;
  right: 0;
  bottom: 0;
}

.withripple {
  position: relative;
}

.ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
  pointer-events: none;
}

.ripple {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  border-radius: 100%;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1);
  transform-origin: 50%;
  opacity: 0;
  pointer-events: none;
}

.ripple.ripple-on {
  transition: opacity 0.15s ease-in 0s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  opacity: 0.1;
}

.ripple.ripple-out {
  transition: opacity 0.1s linear 0s !important;
  opacity: 0;
}

.half-width {
  width: 50%;
}

.col-3-width {
  width: 33.33%;
}

.col-4-width {
  width: 25%;
}

.body-bg-white {
  background-color: $body-bg-white;
}

.bg-body {
  background-color: $body-bg;
}

.background-contain {
  background-size: contain;
  background-repeat: no-repeat;
}

.background-cover {
  background-size: cover;
}

@media (max-width: 1200px) {
  .col-4-width {
    width: 33%;
  }
}

@media (max-width: 840px) {
  .col-3-width {
    width: 50%;
  }

  .col-4-width {
    width: 50%;
  }

  .half-width {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .col-3-width {
    width: 100%;
  }

  .col-4-width {
    width: 100%;
  }
}