/*------------- #FORUMS --------------*/



.forums-table {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;

  thead {
	background-color: $blue;

	th {
	  font-size: 12px;
	  font-weight: 700;
	  padding: 15px 25px;
	  color: $white-color;
	  text-align: center;

	  &.forum {
		text-align: left;
	  }
	}
  }

  td {
	padding: 35px 25px;
	text-align: center;

	&.forum {
	  text-align: left;
	}
  }

  tr {
	border-bottom: 1px solid $border-color;
	border-top: 1px solid $border-color;

	&:last-child {
	  border-bottom: none;
	}
  }

  .count {
	font-size: 12px;

	&:hover {
	  color: $primary-color;
	}
  }
}

.forum-item {
  img {
	float: left;
	margin-right: 15px;
  }

  .content {
	overflow: hidden;
  }

  .title {
	&:hover {
	  color: $primary-color;
	}
  }

  .text {
	margin-bottom: 0;
	font-size: 13px;
  }

  .icon {
	font-size: 15px;
	color: $yellow;
	margin-right: 10px;
	float: left;
  }
}

.author-freshness {
  .author-thumb {
	display: block;
	margin-bottom: 10px;

	img {
	  width: 26px;
	  height: 26px;
	}
  }

  .title {
	display: block;
	font-size: 12px;
	margin-bottom: 0;

	&:hover {
	  color: $primary-color;
	}
  }

  time {
	font-size: 11px;
  }
}

.sub-forums {
  margin-bottom: 0;
  padding: 5px 0;
  margin-top: 15px;
  display: inline-block;

  a {
	font-size: 12px;
	padding: 5px 13px;
	display: block;
	margin-bottom: 0;

	&:hover {
	  color: $primary-color;
	}
  }

  border-left: 1px solid $border-color;
}

.author-started {
  display: flex;
  align-items: center;
  margin-top: 10px;

  & > * + * {
	margin-left: 8px;
  }

  span {
	font-size: 12px;
  }

  .title {
	margin-bottom: 0;
	font-size: 12px;
  }

  .author-thumb img {
	width: 18px;
	height: 18px;
	margin-right: 0;
  }
}


/*------------- Responsive mode styles --------------*/


@media (max-width: 560px) {
  .forum-item  {
	text-align: center;

	img {
	  float: none;
	  margin-right: 0;
	  margin-bottom: 15px;
	}
  }

  .forums-table thead th,
  .forums-table td {
	padding: 5px;
  }
}