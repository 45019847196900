/*------------- #POSTS --------------*/



.post {
  position: relative;
  padding: 25px;
  border-bottom: 1px solid $border-color;

  p {
	margin: 25px 0;
  }

  .control-block-button {
	top: 20px;
	right: -17px;
  }

  &.thumb-full-width {
	.post-thumb {
	  border-radius: 0;
	  margin: 0 -25px 25px;
	}
  }

  .btn {
	margin-bottom: 25px;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	display: inline-block;

	& + p {
	  margin-top: 10px;
	}
  }

  .post__author-name {
	font-weight: 700;
	margin-bottom: 0;
  }

  .more-photos span {
	font-weight: 700;
  }

  &.shared-photo {
	.post-thumb {
	  border: 1px solid $border-color;
	  margin-bottom: 0;
	}
  }

  .author-date a {
	font-weight: 700;
  }
}

.post-block-photo {
  margin-bottom: 20px;
  overflow: hidden;

  a, span {
	float: left;
	overflow: hidden;
	padding: 0 8px 8px 0;

	img {
	  border-radius: 3px;
	  width: 100%;
	}
  }
}

.more-photos {
  position: relative;

  &:after {
  	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 8px;
	bottom: 8px;
	background-color: $primary-color;
	opacity: .8;
	border-radius: 3px;
  }

  span {
	color: $white-color;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 5;
	font-weight: 700;
	margin-left: -8px;

  }
}

.post-thumb {
  border-radius: 5px;
  margin-bottom: 25px;
  overflow: hidden;
  position: relative;

  img {
	width: 100%;
  }
}

.post__author {
  margin-bottom: 20px;

  img {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	overflow: hidden;
	margin-right: 12px;
  }

  .more {
	float: right;
	font-size: 16px;
	margin-right: 20px;
  }
}

.post-additional-info {
  padding: 20px 0 0;
  border-top: 1px solid $border-color;
  display: flex;
  align-items: center;

  & > * {
	margin-right: 15px;

	&:last-child {
	  margin-right: 0;
	  margin-left: auto;
	}
  }
}

.comments-shared {
 & > * {
   margin-right: 15px;

   &:last-child {
	 margin-right: 0;
   }
 }

  span {
	color: $body-font-color;
  }
}

.post-add-icon {
  fill: #c2c5d9;
  color: #c2c5d9;

  & > * {
	vertical-align: middle;
	transition: all .15s ease;
  }

  svg, img {
	margin-right: 8px;
	width: 20px;
	height: 18px;
  }

  .olymp-speech-balloon-icon {
	width: 22px;
	height: 20px;
  }

  .olymp-share-icon {
	width: 26px;
	height: 21px;
  }

  &:hover {
	fill: $primary-color;
	color: $primary-color;

	span {
	  color: $primary-color;
	}
  }

  &:active,
  &:focus {
	fill: #c2c5d9;
	color: #c2c5d9;
  }

}

.names-people-likes {
  font-size: 12px;
  a {
	color: $heading-color;
	font-weight: 700;
  }
}

.post-control-button {
  .btn-control {
	display: block;
	margin-bottom: 6px;
	margin-right: 0;
	background-color: $grey-lighter;
	width: 34px;
	height: 34px;
	line-height: 36px;

	&.bg-facebook {
	  background-color: $c-facebook;

	  &:hover {
		background-color: $c-facebook;
	  }
	}

	&.bg-twitter {
	  background-color: $c-twitter;
	  &:hover {
		background-color: $c-twitter;
	  }
	}

	svg {
	  width: 18px;
	  height: 18px;
	}

	.olymp-share-icon {
	  width: 24px;
	}

	&:hover {
	  background-color: $primary-color;
	}

	&.featured-post {
	  &:hover {
		background-color: #ffc923;
	  }
	}

	&.has-i {

	  svg, i {
		font-size: 15px;
		width: 15px;
		height: 15px;
	  }
	}
  }
}

.empty-post {
  height: 600px;
  background-color: #dce1eb;
  opacity: .5;
}

.empty-post-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .title {
	font-size: 16px;
	font-weight: 700;
	color: #515365;
  }

  span {
	font-size: 11px;
  }
}



/*================= Responsive Mode ============*/


@media (max-width: 768px) {
  .post-control-button .btn-control {
	display: inline-block;
  }

  .post .control-block-button {
	right: 20px;
	top: -13px;
  }

  .post__author .more {
	margin-right: -16px;
  }
}


@media (max-width: 480px) {

  .post-additional-info {
	flex-direction: column;
	align-items: flex-start;
  }

  .post-additional-info > * {
	margin-right: 0;

	& + * {
	  margin-top: 10px;
	}

	&:last-child {
	  margin-left: 0;
	}
  }
}