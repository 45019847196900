@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Harmattan);
@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url('https://fonts.googleapis.com/css?family=Ubuntu');
@import "colours";
// Font Variables
$roboto-slab: 'Roboto', sans-serif;
$harmattan: 'Harmattan', sans-serif;
$ubuntu: 'Ubuntu', sans-serif;
$raleway: 'Raleway', sans-serif;
$noteMargin: 5.25px ;
$noteDefaultWidth: 35px ;
$halfNoteDefaultWidth: 17.5px;
$joinerDefaultWidth: 5.25px;
$cradleLowerMargin: 21px;
$cradleMarginTop: -35px;
$noteWithMarginDefault: $noteMargin + $noteDefaultWidth;


//device sizes
$mobile: "(min-width: 300px) and (max-width: 949px)";
$tablet: "(min-width: 950px) and (max-width: 1099px)";
$desktop: "(min-width: 1100px) and (max-width: 1320px)";
$desktopLarge: "(min-width: 1321px)";

//set shells per line for
$mobileShellsPerLine: 12;
$tabletShellsPerLine: 16;
$desktopShellsPerLine: 20;
$largeDesktopShellsPerLine: 24;

//spacing between end of last cradle and scrollbar
$scrollBarMargin: 10px;

.notationContainer {
    min-width: 300px !important;
    min-height: 300px !important;
    height: calc(100vh - 180px);
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: (($noteDefaultWidth + $noteMargin) * $desktopShellsPerLine) + $scrollBarMargin;

    @media #{$mobile} {
        width: (($noteDefaultWidth + $noteMargin) * $mobileShellsPerLine) + $scrollBarMargin;
    }
    @media #{$tablet} {
        width: (($noteDefaultWidth + $noteMargin) * $tabletShellsPerLine) + $scrollBarMargin;
    }
    @media #{$desktopLarge} {
        width: (($noteDefaultWidth + $noteMargin) * $largeDesktopShellsPerLine) + $scrollBarMargin;
    }
}

.withMargin {
    margin-right: $noteMargin;
}

.noteDefault {
    border-radius: 5px;
    height: 50px;
    width: $noteDefaultWidth;
}

.noteWithMarginDefault {
    width: $noteWithMarginDefault;
    height: 50px;
}

.halfNoteDefault {
    width: $halfNoteDefaultWidth;
    height: 50px;
}

.joinerDefault {
    width: $joinerDefaultWidth;
    height: 50px;
}

.cradleDefault {
    width: $noteDefaultWidth;
    height: 50px;
}

.cradleJoinerDefault {
    width: $joinerDefaultWidth;
    height: 50px;
}

.notation {
    font-family: $harmattan;
}

.notation svg {
    &:hover {
        fill: $altDanger;
    }
}

i.inverted.bordered.icon, i.inverted.circular.icon.bpmIcon {
    background-color: $danger !important;
}

i.inverted.bordered.icon, i.inverted.circular.icon.playIcon {
    background-color: $blue !important;
}

i.inverted.bordered.icon, i.inverted.circular.icon.recordIcon {
    background-color: $altDanger !important;
}

.restNote {
    fill: #7d7d7d
}

.dynamicNote {
    &:hover {
        fill: $altDanger;
    }
}

.cradle {
    margin-right: 0px !important;
    display: inline-block;
    margin-bottom: $cradleLowerMargin;
}

.signatureGroup {
    margin-right: 0px !important;
    display: inline-block;
}

.cradleLines {
    margin-top: $cradleMarginTop;
}

.play-default svg {
    fill: #000;
}

.play-success svg {
    fill: $success;
}

.play-failed svg {
    fill: $warning;
}

.play-next svg {
    fill: $highlight
}

.modal-dialog {
    padding-top: 200px;
}

.recentlyAdded {
    width: 100%;
}

.slick-slide, .slick-slide *{ outline: none !important; }

.notationContainer .slick-dots {margin: unset; margin-top: -35px; bottom: unset}

.demoplayer .notationContainer {max-height: 80vh!important;}


.trackingLine {
    position: absolute;
    height: 50px;
    width: 3px;
}

.slick-prev:before, .slick-next:before {display: none !important;}


