@import "colours";

.genreCard {
    &:hover {
        background-color: lighten($blue, 25%);
        cursor: pointer;
        border: 1px solid rgba(34,36,38,.01) !important;
    }
    font-size: 15pt !important;
    border: 1px solid #FFFFFF !important;
    box-shadow: 1px 1px 2px 0 rgba(34,36,38,.15) !important;
}

.genreTitle {
    text-align: center;
    font-size: 1.2em;
}

.listContainer {
    columns: 3;
}
.playerMenu .item{
    margin: 0px !important;
    padding: 5px !important;
}
.playerMenu .item:hover{
    background: rgba(0,0,0,.05) !important;
    color: rgba(0,0,0,.95) !important;
}

@media screen and (min-width: 501px) and (max-width: 800px) {
  .listContainer {
      columns: 2;
  }
}
@media screen and (max-width: 500px) {
  .listContainer {
      columns: 1;
  }
  .playerMenu .item {
    min-width: 0px !important;
    margin: 0px !important;
  }
}

.instrumentDropdown .menu{
    max-height:400px;
    overflow-y: auto;
}

.noMarginBorder {
    margin:  0px !important;
    padding: 0px !important;
}

.olympus-styles .search-bar.w-search {
    background-color: #fbfbfb !important;
}

.olympus-styles .search-bar .form-group.with-button button {
    fill: #fff273 !important;
}
