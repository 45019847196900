@import "colours";

.signUpBtn {
    background: $blue !important;
    color: $light !important;
}

.signInBtn {
    background: $danger !important;
    color: $light !important;
}

.signInBtn {
    background: $danger !important;
    color: $light !important;
}

.tryDemoBtn {
    background: $purple !important;
    color: $light !important;
}

.confirmationForm label {
    color: $dark;
}

.loginBox {
    display: block;
    min-height: 533px;
    top: 50%;
    margin-left: 5em;
    margin-right: 5em;

}

@media screen and (max-width: 500px) {
    .loginBox {
        width: 350px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.centeredBox {
    max-width: 550px;
    width: 400px;
    text-align: center;
}

.no-bm {
    margin-bottom: 0px !important;
}

.homeView {
    background-color: white;
}

.homeView .playerMenu {
    display: none;
}
.homeView .ui .segment {
    box-shadow: 0 0px 0px 0 !important;
}

.homeView  .ui.card {
    box-shadow: 0 0px 0px 0 !important;
}

.homeView  .ui.card .content {
    border-top: 0px !important;
}

.registration-login-form .btn {
    min-width: 80px;
}



