/*------------- #W-BADGES --------------*/


.w-badges {
  display: inline-block;
  margin: 0 0 -20px -18px;

  li {
	margin: 0 0 20px 15px;
	float: left;
	width: 38px;
	height: 38px;
	border-radius: 100%;
	position: relative;
  }
}