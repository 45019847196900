/*------------- #HEADING --------------*/



.crumina-heading {
  position: relative;
  z-index: 5;
  margin-bottom: 30px;

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & + * {
    margin-top: 25px;
  }
}

.heading-title {
  margin-bottom: 30px;
  line-height: 1.3em;
}

.heading-text {
  margin-top: 20px;
  line-height: 1.75;

  &.small {
    font-size: 14px;
  }

  a {
    font-weight: 700;
  }
}

.heading-sup-title {
  color: $primary-color;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
}

.with-title-decoration {
  .heading-title {
    position: relative;
    padding-bottom: 30px;

    &:after, &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all .3s ease;
    }

    &:after {
      height: 1px;
      background-color: $border-color;
      width: 100%;
    }

    &:before {
      height: 2px;
      background-color: $primary-color;
      width: 10%;
    }
  }

  &:hover {
    .heading-title:before {
      width: 100%;
    }
  }
}

