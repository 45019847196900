@import 'colours';

.homeView .ui.grid {
    margin: 0px !important;
}

.mtude-video {
    padding-top: 180px !important;
}

@media only screen and (max-width: 840px) {
    .bg-stripe {
        display: none !important;
    }
    .mtude-video {
        padding-top: 80px !important;
    }
}

.ralewayFont {
    font-family: 'Raleway', sans-serif;
}

.olympus-styles p {
    font-size: 1.175rem !important;
}

.fullHeight {
    min-height: 100vh;
}

.mtudeBackground {
    background: linear-gradient(to left, #7b4397, #dc2430);
    min-height: calc(50vh - 90px);
}

.mtudeBackground .embed {
    background: #dcddde00;
}

.demoplayer .ui.dimmer > .content > * {
    vertical-align: unset;
    padding-top: 40px;
}

.app-landing {
    padding-top: 60px;
    background-image: url('../assets/background/mobile-landing.png');
    min-height: 100vh;
    width: 100vw;
    background-size: cover;
}

.ie-landing {
    padding-top: 50px;
    background-image: url('../assets/background/ie-landing.jpg');
    min-height: 100vh;
    width: 100vw;
    background-size: cover;
}

.app-landing h2,
.app-landing h3,
.app-landing h4 {
    color: white !important;
}

.ie-landing h2,
.ie-landing h3,
.ie-landing h4 {
    color: white !important;
}

.browserLinks {
    color: white !important;
}

.olympus-styles .registration-login-form .title {
    text-align: center;
}

.olympus-styles .registration-login-form {
    border: unset !important;
    border-radius: unset !important;
}

.howtoplay,
.howtoplay .dimmer {
    padding: 0;
    margin: 0;
    //background-color: rgba(250, 250, 50, 0.8);
}

.ui.segment.dimmable {
    margin: 0px;
    padding: 0px;
}

.instruction {
    border: 0px solid #c90078;
    background-color: #ffffff;
    text-align: center;
}

.instruction p {
    color: #c90078;
    margin: 0;
}

p.continue {
    font-size: 0.9rem;
}
