/*------------- #w-personal-info --------------*/


.w-personal-info {
  margin-bottom: 0;

  li {
	padding: 15px 0;

	.title {
	  color: $heading-color;
	  font-weight: 500;
	  margin-right: 30px;
	  float: left;
	  width: 30%;
	}

	.text {
	  color: $body-font-color;
	  display: block;
	  overflow: hidden;
	}

	.date {
	  display: block;
	  font-size: 11px;
	  color: #bdc0ce;
	  font-weight: 500;
	  margin-bottom: 15px;
	}
  }

  a:hover {
	color: $primary-color;
  }

  &.item-block {
	.title {
	  margin-right: 0;
	  float: none;
	  width: auto;
	}
  }
}

.widget + .widget {
  margin-top: 35px;
}

.widget p {
  font-size: 13px;
}