/*------------- #SHOP --------------*/



.shop-product-item {
  margin-bottom: 30px;

  .product-thumb {
	display: flex;
	background-color: #f2f4f8;
	margin-bottom: 10px;
	margin-right: 0;
	height: 420px;
	width: auto;
	position: relative;
  }

  .product-content {
	display: flex;
	align-items: center;

	.block-price {
	  margin-left: auto;
	}
  }

  .onsale {
	display: block;
	width: 42px;
	height: 42px;
	border-radius: 100%;
	position: absolute;
	right: 20px;
	top: 20px;
	color: $white-color;
	background-color: $primary-color;
	font-size: 10px;
	font-weight: 900;
	text-transform: uppercase;
	text-align: center;
	line-height: 42px;
  }

  .product-content .title {
	font-size: 16px;
	transition: opacity .3s ease;
  }

  .product-price {
	margin-right: 0;
	font-size: 16px;
	font-weight: 700;
	transition: opacity .3s ease;

	&.with-sale {
	  color: $primary-color;

	  del {
		color: $body-font-color;
		margin-right: 7px;
	  }
	}
  }

  .rait-stars {
	margin-bottom: 10px;
  }

  &:hover {
	.in-cart {
	  opacity: 1;
	}

	.rait-stars {
	  opacity: 0;
	}

	.product-price {
	  opacity: 0;
	}
  }

  &.product-item-v2 {
	text-align: center;

	.product-thumb {
	  display: block;
	  margin-bottom: 30px;
	  height: auto;
	  padding: 20px;

	  img {
		display: block;
		margin: 10px auto 20px;
	  }

	  .btn {
		margin: 0 auto;
		opacity: 0;
	  }

	  .rait-stars {
		opacity: 1;
	  }

	  .product-price {
		opacity: 1;
	  }

	  .block-title, .block-price {
		text-align: left;
	  }
	}
  }

  &:hover {
	.product-thumb {
	  .btn {
		opacity: 1;
	  }
	}
  }
}

.product-category {
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 10px;
  color: $body-font-color;
  display: block;
}

.block-price {
  position: relative;

  .in-cart {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(0, -50%);
	opacity: 0;
	width: 40px;
	height: 40px;
	border-radius: 5px;
	text-align: center;
	background-color: $blue;
	transition: opacity .3s ease;

	svg {
	  fill: $white-color;
	  height: 22px;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	}
  }
}



/*------------- Product Details --------------*/


.shop-product-detail {
  .thumbs-wrap {
	display: flex;
	align-items: flex-start;
  }
}

.small-thumbs-wrap {
  margin-right: 10px;

  .small-thumb {
	display: flex;
	width: 80px;
	height: 80px;
	background-color: #f2f4f8;

	img {
	  margin: auto;
	}

	& + .small-thumb {
	  margin-top: 10px;
	}
  }
}


.shop-product-detail-thumb {
  height: 560px;
  width: 100%;
  display: flex;
  background-color: #f2f4f8;

  img {
	margin: auto;
  }
}

.shop-product-detail-content {

  p {
	margin-bottom: 50px;
  }

  .btn {
	margin-bottom: 50px;
  }

  .main-content-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;

	.title {
	  margin-bottom: 20px;
	}
  }

  .product-category {
	font-size: 12px;
	margin-bottom: 20px;
  }

  .rait-stars .star-icon {
	font-size: 10px;
  }

  .product-price {
	font-size: 34px;
  }

  .inputs-wrap {
	display: flex;
	margin-bottom: 50px;
	align-items: center;

	& > * + * {
	  margin-left: 20px;
	  width: 100%;
	}

	.form-group {
	  margin-bottom: 0;
	}
  }
}

.quantity {
  position: relative;

  .quantity-minus, .quantity-plus {
	position: absolute;
	right: 15px;
	top: 0;
	color: $heading-color;
	font-size: 11px;
	height: 50%;
	width: 20px;
	line-height: 27px;
  }

  .quantity-minus {
	top: auto;
	bottom: 0;
  }
}

.tags {
  font-size: 12px;
  color: $heading-color;
  font-weight: 700;

  li {
	display: inline-block;

	&:nth-child(2) {
	  margin-left: 5px;
	}
  }

  .tags-item {
	color: $body-font-color;
	font-weight: 400;

	&:hover {
	  color: $primary-color;
	}
  }
}

.article-number {
  font-size: 12px;
  color: $heading-color;
  font-weight: 700;

  span {
	color: $body-font-color;
	font-weight: 400;
	margin-left: 8px;
  }
}

.product-description {
  .nav-tabs {
	border-bottom: none;
	justify-content: center;
	margin-bottom: 80px;

	.nav-link {
	  font-weight: 700;
	  color: $body-font-color;
	  font-size: 14px;

	  &.active {
		color: $heading-color;

		.total-topic {
		  background-color: $primary-color;
		}
	  }

	  .total-topic {
		width: 19px;
		height: 19px;
		line-height: 19px;
		background-color: $icon-color;
		display: inline-block;
		color: $white-color;
		margin-left: 10px;
	  }
	}
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	border-color: transparent;
  }

  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
	border-color: transparent;
  }

  .tab-pane {
	& > .title {
	  margin-bottom: 50px;
	}

	p {
	  margin-bottom: 30px;
	}
  }
}

.comments-title-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;

  .rait-stars .star-icon {
	font-size: 10px;
  }
}


.comments__item-review {
  padding: 50px 0;
  border-top: 1px solid $border-color;

  &:last-child {
	border-bottom: 1px solid $border-color;
  }

  &:first-child {
	margin-top: 40px;
  }

  .rait-stars {
	margin-bottom: 20px;
  }

  .comment-content {
	margin-bottom: 25px;
  }

  .comments__header-review {
	font-size: 11px;

	.comments__author-review a {
	  font-weight: 700;
	  color: $heading-color;
	  font-style: normal;

	  &:hover {
		color: $primary-color;
	  }
	}
  }
}

.form-write-rewiev {
  padding: 25px 30px;
}


/*================= Responsive Mode ============*/



@media(max-width: 1200px) {
  .quantity .quantity-minus, .quantity .quantity-plus {
	line-height: 22px;
  }
}

@media(max-width: 1024px) {
  .shop-product-detail-thumb {
	height: auto;
  }

  .shop-product-detail .thumbs-wrap {
	margin-bottom: 40px;
  }
}

@media(max-width: 900px) {
  .product-description .nav-tabs {
	margin-bottom: 40px;
  }

  .product-description .tab-pane > .title {
	margin-bottom: 30px;
  }

  .product-description .tab-pane p {
	margin-bottom: 20px;
  }

  .comments__item-review {
	padding: 30px 0;

	&:first-child {
	  margin-top: 20px;
	}
  }
}

@media(max-width: 768px) {
  .product-description .nav-item {
	width: auto;
  }
}

@media(max-width: 460px) {
  .small-thumbs-wrap .small-thumb {
	width: 50px;
	height: 50px;
  }

  .shop-product-detail-content .product-price {
	margin-right: 0;
	font-size: 22px;
  }
}