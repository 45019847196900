/*------------- #W-TWITTER --------------*/


.w-twitter {
  li {
	padding: 25px;
	border-bottom: 1px solid $border-color;

	&:last-child {
	  border-bottom: none;
	}
  }

  .author a {
	line-height: 1;
  }

  .author-name {
	display: block;
	font-weight: 700;
	color: $heading-color;
  }

  .group {
	font-size: 11px;
	color: $body-font-color;
  }

  .verified {
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: #6ec4f7;
	color: $white-color;
	font-size: 5px;
	display: inline-block;
	text-align: center;
	line-height: 10px;
	position: relative;
	top: -2px;
	margin-left: 5px;
  }

  .author-folder {
	margin-bottom: 12px;

	& > * {
	  display: inline-block;
	  vertical-align: middle;
	}

	img {
	  margin-right: 8px;
	}
  }

  .link-post {
	color: $c-twitter;
  }


  .post__date {
	font-size: 12px;
  }
}