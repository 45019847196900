/*------------- #CONTACTS --------------*/



.contact-item-wrap {
  margin-bottom: 30px;

  &:hover {
	.contact-title:after {
	  width: 100%;
	}
  }
}

.contact-title {
  position: relative;
  padding-bottom: 35px;
  margin-bottom: 50px;

  &:after {
	content: "";
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 15%;
	height: 2px;
	background-color: $primary-color;
	transition: width .3s ease-in-out;
  }
}

.contact-item {

  .sub-title {
	font-weight: 400;
  }

  a {
	color: $body-font-color;
	display: block;

	&:hover {
	  color: $primary-color;
	}
  }

  & + .contact-item {
	margin-top: 30px;
  }
}