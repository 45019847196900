.imageUpload {
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.ui-block-content {
    padding-top: 5px !important;
}
