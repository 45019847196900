/*------------- #SKILLS --------------*/



.skills-item {
  margin-bottom: 20px;

  .skills-item-meter {
	padding: 0;
	width: 100%;
	border-radius: 10px;
	background-color: #ebecf2;
	position: relative;
	height: 6px;
  }

}

.skills-item-info {
  margin-bottom: 16px;
  color: $heading-color;
  font-size: 12px;

  .skills-item-count {
	float: right;
  }

}

.skills-item-meter-active {
  position: relative;
  height: 100%;
  display: inline-block;
  border-radius: 10px;
  opacity: 0;
  transition: all .2s ease;
  top: -8px;

  &.skills-animate {
	animation: skills-animated 1.2s ease-out;
  }

  &.skills-animate-opacity {
	animation: skills-animated-opacity .2s ease-out;
  }
}

@keyframes skills-animated {
  0% {width: 0;}
}

@keyframes skills-animated-opacity {
  100% {opacity: 1;}
}