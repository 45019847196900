/*------------- #VIDEO BACKGROUND --------------*/


.crumina-video-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: none;

  &:after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(255, 94, 58, .6);
  }

  .video-background {
	min-width: 100%;
	min-height: 100%;
	margin: auto;
	position: absolute;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	visibility: visible;
	opacity: 1;
  }
}
